import React, { useEffect } from 'react';
import { Login, Dashboard } from './views';
import 'rsuite/dist/styles/rsuite-default.css';
import 'antd/dist/antd.min.css';
import 'react-tagsinput/react-tagsinput.css';
import 'remixicon/fonts/remixicon.css';
import './scss/app.scss';
import {
  setSettings,
  setArchived,
  setWilayas,
  setTypes,
  setSpecs,
  setTransactions,
  setCategories
} from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import axios, { setHeaders } from './helpers/Api';
import { localize, setI18nConfig } from './helpers/localization/I18n';
import AppLoader from './components/AppLoader';
import { Alert } from 'rsuite';

function App() {
  setI18nConfig();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const fetchData = async () => {
    try {
      const { data: settings } = await axios.get('/setting/fetch');
      const { data: archived } = await axios.get('/property/fetch', {
        params: { filters: { status: 'ARCHIVED' } }
      });
      const { data: transactions } = await axios.get('/transaction/fetch');
      const { data: types } = await axios.get('/property/type/fetch');
      const { data: specs } = await axios.get('/spec/fetch');
      const { data: wilayas } = await axios.get('/utils/wilayas');
      const { data: categories } = await axios.get('/category/fetch');
      dispatch(setSettings(settings));
      dispatch(setArchived(archived));
      dispatch(setTransactions(transactions));
      dispatch(setTypes(types));
      dispatch(setSpecs(specs));
      dispatch(setWilayas(wilayas));
      dispatch(setCategories(categories));
    } catch (error) {
      if (error.response && error.response.status !== 403) {
        Alert.error(localize('Erreur Interne 500'));
      }
    }
  };
  let refresh = true;
  useEffect(() => {
    if (auth?.accessToken && refresh) {
      const { accessToken, deviceKey, userObj } = auth;
      setHeaders(accessToken, deviceKey, userObj.id);
      fetchData();
    }
  }, [auth]);

  return (
    <div className="App">
      <React.Suspense fallback={<AppLoader />}>
        {auth?.accessToken ? <Dashboard /> : <Login />}
      </React.Suspense>
    </div>
  );
}

export default App;
