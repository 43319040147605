import axios from 'axios';
import {
  fetchClientRdvNotifications,
  fetchPropRdvNotifications,
  fetchReminderNotifications,
  setDefaultState
} from '../store/actions';
import { store } from '../store';

axios.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const shouldUpdateNotifications = config?.params?.shouldUpdateNotifications || undefined;
      if (shouldUpdateNotifications) {
        store.dispatch(fetchPropRdvNotifications());
        store.dispatch(fetchClientRdvNotifications());
        store.dispatch(fetchReminderNotifications());
      }
    }
    return config;
  },
  (error) => {
    console.log({ error });
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      store?.dispatch(setDefaultState());
      //window.location.replace('/')
    }
    return Promise.reject(error);
  }
);
export const setHeaders = async (accessToken, deviceKey, userId) => {
  try {
    axios.defaults.headers.common['Authorization'] = accessToken;
    axios.defaults.headers.common['key'] = deviceKey;
    axios.defaults.headers.common['id'] = userId;
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export default axios;
