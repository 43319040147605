import React from 'react';
import HashLoader from 'react-spinners/HashLoader';
function AppLoader() {
  return (
    <div className="app_loader">
      <HashLoader size={70} color={'#EB3626'} loading={true} />
    </div>
  );
}

export default AppLoader;
