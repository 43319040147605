import * as types from './types';
import axios from '../helpers/Api';
import { Alert } from 'rsuite';
import { localize } from '../helpers/localization/I18n';
export const setAuth = (payload) => ({
  type: types.SET_AUTH,
  payload
});
export const setTransactions = (payload) => ({
  type: types.SET_TRANSACTIONS,
  payload
});

export const setTypes = (payload) => ({
  type: types.SET_TYPES,
  payload
});

export const setSpecs = (payload) => ({
  type: types.SET_SPECS,
  payload
});

export const setWilayas = (payload) => ({
  type: types.SET_WILAYAS,
  payload
});

export const setCategories = (payload) => ({
  type: types.SET_CATEGORIES,
  payload
});

export const setProperty = (payload) => ({
  type: types.SET_PROPERTY,
  payload
});

export const setArchived = (payload) => ({
  type: types.SET_ARCHIVED,
  payload
});

export const setMedia = (payload) => ({
  type: types.SET_MEDIA,
  payload
});

export const delMedia = (payload) => ({
  type: types.DEL_MEDIA,
  payload
});

export const setPropertyStatus = (payload) => ({
  type: types.SET_PROPERTY_STATUS,
  payload
});

// CLIENT APPS
export const setClientAppsPage = (payload) => ({
  type: types.SET_C_APPS_PAGE,
  payload
});
export const setClientApps = (payload) => ({
  type: types.SET_C_APPS,
  payload
});

export const addClientApp = (payload) => ({
  type: types.ADD_C_APP,
  payload
});

export const setClientApp = (payload) => ({
  type: types.SET_C_APP,
  payload
});

export const delClientApp = (payload) => ({
  type: types.DEL_C_APP,
  payload
});

// PROPERTY APPS
export const setPropAppsPage = (payload) => ({
  type: types.SET_P_APPS_PAGE,
  payload
});
export const setPropApps = (payload) => ({
  type: types.SET_P_APPS,
  payload
});

export const addPropApp = (payload) => ({
  type: types.ADD_P_APP,
  payload
});

export const setPropApp = (payload) => ({
  type: types.SET_P_APP,
  payload
});

export const delPropApp = (payload) => ({
  type: types.DEL_P_APP,
  payload
});

// PROPS
export const setPropsData = (payload) => ({
  type: types.SET_PROPS,
  payload
});

export const setCurrentMatchedProps = (payload) => ({
  type: types.SET_CURRENT_MATCHED_PROPS,
  payload
});

export const setCurrentMatchedSeeks = (payload) => ({
  type: types.SET_CURRENT_MATCHED_SEEKS,
  payload
});

export const setPropertiesPage = (payload) => ({
  type: types.SET_PROPS_PAGE,
  payload
});

export const setProp = (payload) => ({
  type: types.SET_PROP,
  payload
});

export const delProp = (payload) => ({
  type: types.DEL_PROP,
  payload
});

// USERS :
export const setUsers = (payload) => ({
  type: types.SET_USERS,
  payload
});

export const delUsers = (payload) => ({
  type: types.DEL_USERS,
  payload
});

export const setUsersPage = (payload) => ({
  type: types.SET_USERS_PAGE,
  payload
});

// Inovices :
export const setFactures = (payload) => ({
  type: types.SET_FACTURES,
  payload
});

export const setFacturesPage = (payload) => ({
  type: types.SET_FACTURES_PAGE,
  payload
});

export const setDevis = (payload) => ({
  type: types.SET_DEVIS,
  payload
});
export const setDevisPage = (payload) => ({
  type: types.SET_DEVIS_PAGE,
  payload
});

// Payments
export const setPayments = (payload) => ({
  type: types.SET_PAYMENTS,
  payload
});
export const setPaymentsPage = (payload) => ({
  type: types.SET_PAYMENTS_PAGE,
  payload
});

// Settings :
export const setSettings = (payload) => ({
  type: types.SET_SETTINGS,
  payload
});
// Recherche

export const setSeeksData = (payload) => ({
  type: types.SET_SEEKS,
  payload
});
export const fetchPropMatchNotifications = () => (dispatch) => {
  axios
    .get('/property/match/notifications')
    .then(({ data: payload }) =>
      dispatch({
        type: types.SET_PROP_MATCH_NOTIFICATION,
        payload
      })
    )
    .catch(() => Alert.error(localize('Erreur Interne 500')));
};

export const fetchSeeksMatchNotifications = () => (dispatch) => {
  axios
    .get('/seek/match/notifications')
    .then(({ data: payload = [] }) =>
      dispatch({
        type: types.SET_SEEK_MATCH_NOTIFICATION,
        payload
      })
    )
    .catch(() => Alert.error(localize('Erreur Interne 500')));
};

export const fetchClientRdvNotifications = () => (dispatch) => {
  axios
    .get('/appointment/fetch/client/notification')
    .then(({ data: payload }) =>
      dispatch({
        type: types.SET_CLIENT_RDV_NOTIFICATION,
        payload
      })
    )
    .catch(() => Alert.error(localize('Erreur Interne 500')));
};

export const fetchPropRdvNotifications = () => (dispatch) => {
  axios
    .get('/appointment/fetch/property/notification')
    .then(({ data: payload }) => {
      console.log({ HERE: payload });
      dispatch({
        type: types.SET_PROP_RDV_NOTIFICATION,
        payload
      });
    })
    .catch(() => Alert.error(localize('Erreur Interne 500')));
};

export const fetchReminderNotifications = () => (dispatch) => {
  axios
    .get('/property/fetch/archives/notifications')
    .then(({ data: payload }) => {
      dispatch({
        type: types.SET_REMINDER_NOTIFICATION,
        payload
      });
    })
    .catch(() => Alert.error(localize('Erreur Interne 500')));
};

export const setLocalFetchNumber = (payload) => ({
  type: types.SET_FETCH_NUMBER,
  payload
});
export const setShouldFetchNotifications = (payload) => ({
  type: types.SET_SHOULD_FETCH,
  payload
});

export const setServerFetchNumber = () => (dispatch) => {
  axios.put('/setting/update/notifications/fetch-number').then(({ data: payload }) => {
    dispatch({
      type: types.SET_SETTINGS,
      payload
    });
  });
};

export const setUserObj = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post('/user/session', { id: getState()?.auth?.userObj?.id })
      .then(({ data: payload }) => {
        dispatch({
          type: types.SET_USER_OBJ,
          payload
        });
        resolve(payload);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const setDefaultState = (payload) => ({
  type: types.SET_DEFAULT_STATE,
  payload
});
