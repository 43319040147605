import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';
import { memoize } from 'lodash';
import fr from './locales/fr.json';
import en from './locales/en.json';

const translationGetters = { fr, en };

const localize = memoize(
  (key, config) => (config ? i18n.t(key, config) : i18n.t(key)),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

const setI18nConfig = () => {
  const fallback = {
    languageTag: 'fr'
  };
  const { languageTag } =
    RNLocalize.findBestLanguageTag(Object.keys(translationGetters)) || fallback;

  localize?.cache?.clear && localize.cache?.clear();

  i18n.translations = {
    [languageTag]: translationGetters[languageTag]
  };
  i18n.locale = languageTag;
};

const getCurrentLocale = () => {
  const languageTag = i18n.currentLocale().split('-').shift();
  if (languageTag && languageTag in translationGetters.keys) {
    return languageTag;
  }
  return 'fr';
};

const setCurrentLocale = (languageTag) => {
  i18n.locale = languageTag;
};

export { localize, setI18nConfig, getCurrentLocale, setCurrentLocale };
