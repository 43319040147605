import React from 'react';

export const Login = React.lazy(() => import('./Login/Login'));
export const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
export const Properties = React.lazy(() => import('./Properties/Properties'));
export const Trash = React.lazy(() => import('./Trash/Trash'));
export const Archive = React.lazy(() => import('./Archive/Archive'));
export const PropertyAppointment = React.lazy(() =>
  import('./ProppertyAppoinment/PropertyAppointments')
);

export const ClientAppointment = React.lazy(() => import('./ClientAppointment/Clientappointment'));

export const Users = React.lazy(() => import('./Users/Users'));

export const Settings = React.lazy(() => import('./Settings'));

export const Factures = React.lazy(() => import('./Factures'));

export const Devis = React.lazy(() => import('./Devis'));

export const Payments = React.lazy(() => import('./Payments'));

export const Recap = React.lazy(() => import('./Recap/Recap'));

export const PropertySearch = React.lazy(() => import('./PropertySearch'));

export const PropertyMatchList = React.lazy(() => import('./PropertyMatchList'));

export const ClientMatchList = React.lazy(() => import('./ClientMatchList'));

export const PropertiesBlackList = React.lazy(() => import('./BlackList/PropertiesBlackList'));

export const SearchBlackList = React.lazy(() => import('./BlackList/SearchBlackList'));
