/* eslint-disable no-case-declarations */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as types from './types';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// eslint-disable-next-line no-undef
const ENABLE_REDUX_DEV_TOOLS = process.env.NODE_ENV === 'development';

const middleware = ENABLE_REDUX_DEV_TOOLS ? [logger, thunk] : [thunk];
export const defaultState = {
  auth: undefined,
  transactions: [],
  types: [],
  specs: [],
  wilayas: [],
  categories: [],
  property: undefined,
  propertiesPage: 1,
  archived: [],
  properties: {
    data: [],
    current: undefined,
    page: 1
  },
  seeks: {
    data: [],
    current: undefined,
    page: 1
  },

  pAppointments: {
    data: [],
    page: 1
  },
  cAppointments: {
    data: [],
    page: 1
  },
  users: {
    data: [],
    page: 1
  },
  factures: {
    data: [],
    page: 1
  },
  devis: {
    data: [],
    page: 1
  },
  payments: {
    data: [],
    page: 1
  },
  settings: {
    notification: {
      archived: { numberOfDays: 15 },
      appointment: { numberOfDays: 0 },
      fetchNumber: 0
    }
  },
  seekProps: [],
  notification: {
    clientRdv: [],
    propertyRdv: [],
    seeksMatch: [],
    propertiesMatch: [],
    reminder: [],
    fetchNumber: -1,
    shouldFetch: false
  }
};
const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case types.SET_AUTH:
      return {
        ...state,
        auth: payload
      };
    case types.SET_USER_OBJ:
      return {
        ...state,
        auth: { ...state.auth, userObj: payload }
      };
    case types.SET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload
      };
    case types.SET_TYPES:
      return {
        ...state,
        types: payload
      };
    case types.SET_SPECS:
      return {
        ...state,
        specs: payload
      };
    case types.SET_WILAYAS:
      return {
        ...state,
        wilayas: payload
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: payload
      };
    case types.SET_PROPS:
      return {
        ...state,
        properties: { ...state.properties, data: payload }
      };
    case types.SET_ARCHIVED:
      return {
        ...state,
        archived: payload
      };
    case types.DEL_PROP:
      return {
        ...state,
        propertis: {
          ...state.properties,
          data: state.properties.data.filter((d) => !payload.includes(d.id))
        }
      };
    case types.SET_PROPERTY:
      console.log(payload);
      return {
        ...state,
        property: payload
      };
    case types.SET_CURRENT_MATCHED_PROPS:
      console.log(payload);
      return {
        ...state,
        properties: { ...state.properties, current: payload }
      };
    case types.SET_PROPS_PAGE:
      return {
        ...state,
        properties: { ...state.properties, page: payload }
      };
    case types.SET_MEDIA:
      return {
        ...state,
        propery: {
          ...state.property,
          medias: payload
        }
      };
    case types.DEL_MEDIA:
      return {
        ...state,
        property: {
          ...state.property,
          medias: state.medias.filter((m) => m.path !== payload)
        }
      };
    case types.SET_PROPERTY_STATUS:
      console.log(payload, 'status');
      return {
        ...state,
        property: {
          ...state.property,
          status: payload
        }
      };
    case types.SET_C_APPS:
      return {
        ...state,
        cAppointments: { ...state.cAppointments, data: payload }
      };
    case types.SET_C_APPS_PAGE:
      return {
        ...state,
        cAppointments: { ...state.cAppointments, page: payload }
      };
    case types.SET_C_APP:
      const cClone = [...state.cAppointments.data];
      const cIndex = state.cAppointments.data.findIndex((c) => c.id === payload.id);
      cClone[cIndex] = payload;
      return {
        ...state,
        cAppointments: { ...state.cAppointments, data: cClone }
      };
    case types.DEL_C_APP:
      return {
        ...state,
        cAppointments: {
          ...state.cAppointments,
          data: state.cAppointments.data.filter((c) => !payload.includes(c.id))
        }
      };

    case types.SET_P_APPS:
      return {
        ...state,
        pAppointments: { ...state.pAppointments, data: payload }
      };
    case types.SET_P_APPS_PAGE:
      return {
        ...state,
        pAppointments: { ...state.pAppointments, page: payload }
      };
    case types.SET_P_APP:
      const pClone = [...state.pAppointments.data];
      const pIndex = state.pAppointments.data.findIndex((c) => c.id === payload.id);
      pClone[pIndex] = payload;
      return {
        ...state,
        pAppointments: { ...state.pAppointments, data: pClone }
      };
    case types.DEL_P_APP:
      return {
        ...state,
        pAppointments: {
          ...state.pAppointments,
          data: state.pAppointments.data.filter((p) => !payload.includes(p.id))
        }
      };
    case types.SET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          data: payload
        }
      };
    case types.DEL_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter((u) => !payload.includes(u.id))
        }
      };
    case types.SET_USERS_PAGE:
      return {
        ...state,
        users: {
          ...state.users,
          page: payload
        }
      };
    case types.SET_FACTURES:
      return {
        ...state,
        factures: {
          ...state.factures,
          data: payload
        }
      };
    case types.SET_FACTURES_PAGE:
      return {
        ...state,
        factures: {
          ...state.factures,
          page: payload
        }
      };
    case types.SET_DEVIS:
      return {
        ...state,
        devis: {
          ...state.devis,
          data: payload
        }
      };
    case types.SET_DEVIS_PAGE:
      return {
        ...state,
        devis: {
          ...state.devis,
          page: payload
        }
      };
    case types.SET_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          data: payload
        }
      };
    case types.SET_PAYMENTS_PAGE:
      return {
        ...state,
        payments: {
          ...state.payments,
          page: payload
        }
      };
    case types.SET_SETTINGS:
      return {
        ...state,
        settings: payload
      };
    case types.SET_SEEK_PROPS:
      return {
        ...state,
        seekProps: payload
      };
    case types.SET_SEEKS:
      return {
        ...state,
        seeks: {
          ...state.seeks,
          data: payload
        }
      };
    case types.SET_CURRENT_MATCHED_SEEKS:
      console.log(payload);
      return {
        ...state,
        seeks: {
          ...state.seeks,
          current: payload
        }
      };
    case types.SET_PROP_MATCH_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          propertiesMatch: payload
        }
      };
    case types.SET_SEEK_MATCH_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          seeksMatch: payload
        }
      };
    case types.SET_CLIENT_RDV_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          clientRdv: payload
        }
      };
    case types.SET_PROP_RDV_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          propertyRdv: payload
        }
      };
    case types.SET_REMINDER_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          reminder: payload
        }
      };
    case types.SET_FETCH_NUMBER:
      return {
        ...state,
        notification: {
          ...state.notification,
          fetchNumber: payload
        }
      };
    case types.SET_SHOULD_FETCH:
      return {
        ...state,
        notification: {
          ...state.notification,
          shouldFetch: payload
        }
      };

    case types.SET_DEFAULT_STATE:
      return defaultState;
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middlewareEnhancer = applyMiddleware(...middleware);

const store = createStore(persistedReducer, defaultState, middlewareEnhancer);

const persistor = persistStore(store);

export { store, persistor };
