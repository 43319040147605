export const SET_AUTH = 'SET_AUTH';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TYPES = 'SET_TYPES';
export const SET_SPECS = 'SET_SPECS';
export const SET_WILAYAS = 'SET_WILAYAS';
export const SET_PROPERTY = 'SET_PROPERTY';
export const DEL_MEDIA = 'DEL_MEDIA';
export const SET_MEDIA = 'SET_MEDIA';
export const SET_PROPERTY_STATUS = 'SET_PROPERTY_STATUS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ARCHIVED = 'SET_ARCHIVED';

// PROPERTIES
export const SET_PROPS = 'SET_PROP';
export const SET_CURRENT_MATCHED_PROPS = 'SET_CURRENT_MATCHED_PROPS';
export const SET_CURRENT_MATCHED_SEEKS = 'SET_CURRENT_MATCHED_SEEKS';
export const ADD_PROP = 'ADD_PROP';
export const SET_PROP = 'SET_PROP';
export const DEL_PROP = 'DEL_PROP';
export const SET_PROPS_PAGE = 'SET_PROPS_PAGE';

// PREPRTY APPOINTMENTS
export const SET_P_APPS = 'SET_P_APPS';
export const ADD_P_APP = 'ADD_P_APP';
export const SET_P_APP = 'SET_P_APP';
export const DEL_P_APP = 'DEL_P_APP';
export const SET_P_APPS_PAGE = 'SET_P_APPS_PAGE';

// CLIENT APPOINTMENTS
export const SET_C_APPS = 'SET_C_APP';
export const ADD_C_APP = 'ADD_C_APP';
export const SET_C_APP = 'SET_C_APP';
export const DEL_C_APP = 'DEL_C_APP';
export const SET_C_APPS_PAGE = 'SET_C_APPS_PAGE';

// USERS
export const SET_USERS = 'SET_USERS';
export const SET_USER_OBJ = 'SET_USER_OBJ';
export const DEL_USERS = 'DEL_USERS';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';

// Inovices
export const SET_FACTURES = 'SET_FACTURES';
export const SET_FACTURES_PAGE = 'SET_FACTURES_PAGES';

export const SET_DEVIS = 'SET_DEVIS';
export const SET_DEVIS_PAGE = 'SET_DEVIS_PAGE';

// Payment
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_PAYMENTS_PAGE = 'SET_PAYMENTS_PAGE';

// Settings
export const SET_SETTINGS = 'SET_SETTINGS';

//Recherche
export const SET_SEEK_PROPS = 'SET_SEEK_PROPS';
export const SET_SEEKS = 'SET_SEEKS';
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const SET_SEEK_MATCH_NOTIFICATION = 'SET_SEEK_NOTIFICATION';
export const SET_PROP_MATCH_NOTIFICATION = 'SET_PROP_NOTIFICATION';
export const SET_CLIENT_RDV_NOTIFICATION = 'SET_CLIENT_RDV_NOTIFICATION';
export const SET_PROP_RDV_NOTIFICATION = 'SET_PROP_RDV_NOTIFICATION';
export const SET_REMINDER_NOTIFICATION = 'SET_REMINDER_NOTIFICATION';

//config
export const SET_FETCH_NUMBER = 'SET_FETCH_NUMBER';
export const SET_SHOULD_FETCH = 'SET_SHOULD_FETCH';
